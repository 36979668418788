// stores/sidebar.js
import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    isSidebarOpen: false,
    isDropdownOpen: false, // 新增的下拉菜单状态
  }),
  actions: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    toggleDropdown() { // 新增的切换下拉菜单状态的方法
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
});
