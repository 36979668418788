<template>
  <div class="leading-normal tracking-normal" id="main-body">
    <div class="flex flex-wrap">
      <BaseSidebar />
      <!-- 使用 session.value.sideBarOpen 来判断是否应该显示遮罩 -->
      <div class="w-full bg-gray-100 pl-0 lg:pl-64 min-h-screen" :class="sidebarStore.isSidebarOpen ? 'overlay' : ''" id="main-content">
        <BaseNavbar />
        <div class="p-6 bg-gray-100 mb-20">
          <slot />
        </div>
        <BaseFooter />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSidebarStore } from '@/stores/sidebar';

const sidebarStore = useSidebarStore();
</script>
